@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 10px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background: #888;
    border-radius: 1px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #535151;
    display: hidden;
    /* border-radius: 5px; */
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}